import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import SgHeadings from "../components/sg-headings"
import { BgImage } from "gbimage-bridge"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

// Add Page Head component and pass in SEO variables from GraphQL
export const Head = ({ data }) => {
  const title =  "Welcome to Steve Things - Free stuff about data"
  const description = data.strapiGlobal.defaultSeo.metaDescription
  return (
    <SEO title={title} description={description} />
  )
}

// Add page variables
export default function DownloadPage ( { data } ) {

const background = getImage(data.aboutBg)
const shortDesc = "I hope you enjoy the eBook and the Steve Things newsletter."
const headline = "Welcome to Steve Things - Free stuff about data"
const body = "Steve things is a newsletter about data. How to use it. How you shouldn't use it and lots of other insights. It's free will come out once a week and you may unsubscribe at any time. In the meantime you may download the book by clicking the image below. "
const pdfImage = getImage(data.fileImg)

// return page layout 
return (
        <Layout>
          <div className="relative w-full h-full bg-gradient-to-r from-gray-800 to-red-700">
            <BgImage 
              image={background} 
              alt="Thanks for your request - information picture" 
              className="absolute h-96 bg-center object-cover mix-blend-overlay" />
                <div className="container absolute top-0 left-1/2 -translate-x-1/2">
                  <SgHeadings title={headline} description={shortDesc} />
                </div>  
          </div>
            <div className="container mt-4 space-y-4 pb-6">
              {body}
            </div>
            <div className="container mt-4 space-y-4 pb-6">
            <a href="https://eu1.hubs.ly/H02Q89P0"><GatsbyImage image={pdfImage} className="w-1/4 shadow-2xl rounded" alt="Unmask Your customers White paper" /></a>
            </div>
        </Layout>
      )
    }

// query from Strapi

export const query = graphql`    
query DownloadQuery {
    strapiGlobal {
        defaultSeo {
          metaTitle
          metaDescription
        }
      }
      aboutBg: file(relativePath: {eq: "images/about.jpg"}) {
        childImageSharp {
          gatsbyImageData
        }
      }
      fileImg: file(relativePath: {eq: "images/cdp_cover.png"}) {
        childImageSharp {
          gatsbyImageData
        }
    }
  }
  `
